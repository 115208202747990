import 'dropcap.js'
import React from 'react'
import _uniqueId from 'lodash.uniqueid'

class OurDropcap extends React.Component {
  constructor() {
    super()

    this.state = {
      id: '',
      rotate: 0, // deg
      translateX: 0, // %
      translateY: 0, // %
      skewX: 0, // deg
      skewY: 0, // deg
    }
  }

  componentWillMount() {
    const id = this.state.id === '' ? _uniqueId('Dropcap-') : this.state.id

    this.setState({ id: id })
  }

  formatCSS() {
    const state = this.state
    return `#${state.id}:after {
      transform: rotate(${state.rotate}deg) translate(${state.translateX}%, ${
      state.translateY
    }%) skew(${state.skewX}deg, ${state.skewY}deg);
    }`
  }

  appendStylesToHead(doc) {
    doc = doc || document
    const headID = `js-Dropcap-${this.state.id}-styles`
    const headStyles = doc.createElement('style')

    // Could do this with a portal now?
    headStyles.type = 'text/css'
    headStyles.innerHTML = this.formatCSS()
    headStyles.id = headID
    doc.head.appendChild(headStyles)
  }

  updateStylesInHead(doc) {
    doc = doc || document
    let headStyles = document.getElementById(
      `js-Dropcap-${this.state.id}-styles`
    )

    if (headStyles) {
      headStyles.innerHTML = this.formatCSS()
    }
  }

  setDropcap(el, lines, baseline) {
    // Shouldn’t need to set ID here since it’s done in JSX, but
    // maybe Dropcap.js is clearing all the existing attributes?
    if (typeof el !== 'undefined') {
      el.id = this.state.id
    }
    return window.Dropcap.layout(el, lines, baseline)
  }

  componentDidMount() {
    this.appendStylesToHead()

    // TODO FontFaceObserver, or does Dropcap.js do this?
    this.setDropcap(this.el, this.props.lines, this.props.baseline)

    // window.addEventListener('resize', this.setDropcap)
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.setDropcap)
  }

  render() {
    const props = this.props
    let letter = props.children
    // letter = letter.toUpperCase()

    return (
      <span
        id={this.state.id}
        className={`Dropcap js-Dropcap ${props.className}`}
        ref={el => (this.el = el)}>
        {letter}
      </span>
    )
  }
}

OurDropcap.defaultProps = {
  lines: 3,
  baseline: undefined
}

export default OurDropcap
