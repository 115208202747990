import React from "react";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

class Gallery extends React.Component {
  constructor() {
    super();

    this.state = {
      activeIndex: null,
    };

    this.wrapperRef = React.createRef();
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleZoomChange = this.handleZoomChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(e) {
    const activeIndex = this.state.activeIndex;
    const props = this.props;

    if (activeIndex === null) {
      return;
    }

    if (e.key === "ArrowLeft" || e.keyCode === 37) {
      let newActiveIndex = Math.max(activeIndex - 1, 0);
      this.setState({ activeIndex: newActiveIndex });
    } else if (e.key === "ArrowRight" || e.keyCode === 39) {
      let newActiveIndex = Math.min(activeIndex + 1, props.children.length - 1);
      this.setState({ activeIndex: newActiveIndex });
    }
  }

  handleZoomChange(isZoomed, imageIndex) {
    if (isZoomed) {
      // Change index
      this.setState({
        activeIndex: imageIndex,
      });
      return;
    }

    if (isZoomed === false && this.state.activeIndex !== imageIndex) {
      return;
    }

    // Close
    this.setState({ activeIndex: null });
    window.setTimeout(this.forceBodyScrollEnable, 300);
    window.setTimeout(this.forceBodyScrollEnable, 1000);
  }

  // This is a hacky way to work around the dialog body not getting properly reset
  // by react-medium-image-zoom…could be an issue with this callback, or it could
  // be an issue with accounting for the CSS transition. Either way, this seems to fix it.
  forceBodyScrollEnable() {
    if (document && document.body) {
      document.body.style.width = "100%";
      document.body.style.overflow = "auto";
    }
  }

  render() {
    const { children, ...remainingProps } = this.props;
    const state = this.state;

    return (
      <div>
        {/* This is a flexbox hack */}
        <div aria-hidden style={{ opacity: 0, height: 0 }}>
          It has been my idea of a really versatile text face right from the
          beginning, to combine a very comprehensive character repertoire with
          the best possible typographic craftsmanship and design choices.
        </div>

        <div className="Gallery">
          {children.map((image, imageIndex) => {
            let keyStr = `${remainingProps.name}_${imageIndex}`;
            return (
              <div key={keyStr} className="Gallery__item">
                <ControlledZoom
                  classDialog={`Gallery__dialog ${
                    state.activeIndex !== null ? "Gallery__dialog--open" : ""
                  }`}
                  isZoomed={state.activeIndex === imageIndex}
                  onZoomChange={(isZoomed) =>
                    this.handleZoomChange(isZoomed, imageIndex)
                  }
                >
                  {image}
                </ControlledZoom>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

Gallery.defaultProps = {
  name: "Gallery",
  cellSpacing: 40,
  wrapAround: true,
  autoPlayInterval: 3000,
};

export default Gallery;
