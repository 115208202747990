import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { LocaleContext } from "gatsby-theme-i18n";

const Seo = ({ image, description, title, children }) => {
  const { t } = useTranslation();
  const locale = React.useContext(LocaleContext);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            author
            image
          }
        }
      }
    `
  );

  const titleGlobal = t("title") || site.siteMetadata.title
  const siteUrl = site.siteMetadata.siteUrl;
  const metaDescription = description || t("description");
  let metaImage = image || site.siteMetadata.image;
  if (metaImage) {
    metaImage = `${siteUrl}/images/${locale}/${metaImage}`;
  }

  return (
    <Helmet title={title} titleTemplate={`%s | ${titleGlobal}`}>
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />
      {metaImage ? <meta name="og:image" content={metaImage} /> : null}
      <meta
        name="twitter:card"
        content={metaImage ? "summary_large_image" : "summary"}
      />
      {/* <meta name="twitter:creator" content={site.siteMetadata.author} /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {children}
    </Helmet>
  );
};

export default Seo;
