import React from "react";
import TableOfContents from "./TableOfContents";

const Article = ({ showToc, showTocScroll, ...props }) => {
  const showTitle = props.title && props.showTitle;

  return (
    <article
      className={[
        `Article ${showToc ? "Article--with-toc" : ""}`,
        `${showTitle ? "Article--with-title" : ""}`,
        `${showTocScroll ? "Article--with-toc-scroll" : ""}`,
      ].join(" ")}
    >
      <div className="Article__wrapper">
        {showToc ? (
          <TableOfContents tableOfContents={props.tableOfContents} />
        ) : null}

        <div className="Article__content">
          {showTitle ? (
            <header className="Article__header">
              <h1>{props.title}</h1>
            </header>
          ) : null}

          <div>{props.children}</div>
        </div>
      </div>
    </article>
  );
};

Article.defaultProps = {
  showToc: false,
  showTitle: true,
  tableOfContents: null,
};

export default Article;
