import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Figure = (props) => {
  const { caption } = props;

  let figcaption = null;
  let img = props.children || (
    <img src="https://picsum.photos/1400/800" alt="" />
  );

  if (caption) {
    figcaption = <figcaption className="Figure__caption">{caption}</figcaption>;
  }

  if (props.zoom) {
    img = <Zoom>{img}</Zoom>;
  }

  return (
    <figure className={`Figure ${props.rounded ? "Figure--rounded" : ""} ${props.narrow ? "Figure--narrow" : ""}`}>
      <div className="Figure__image-container">
        <div className="Figure__image-wrapper">{img}</div>
      </div>
      {figcaption}
    </figure>
  );
};

Figure.defaultProps = {
  caption: "",
  rounded: false,
  zoom: true,
  narrow: false
};

export default Figure;
