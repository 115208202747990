// MIT via https://github.com/emgoto/emgoto.com/
// https://github.com/emgoto/emgoto.com/blob/master/LICENSE

import React, { useState } from "react";
import { useIntersectionObserver, useHeadingsData } from "./utils";

const HeadingLink = ({ heading, activeIndex, children }) => {
  const isActive = activeIndex === heading.index;

  return (
    <li>
      <div>
        <a
          onClick={(e) => {
            // This has to be redone in JS to stop headroom from breaking…yeah.
            e.preventDefault();
            let href = e.target.getAttribute("href");
            href = href.replace('#', '');
            if (href) {
              let el = document.getElementById(href);
              if (el) {
                el.scrollIntoView();
              }
            }
          }}
          href={heading.url}
          className={`TableOfContents__link ${
            isActive ? "TableOfContents__link--active" : ""
          }`}
        >
          {heading.title}
        </a>
      </div>
      {children}
    </li>
  );
};

const HeadingsList = ({ headings, activeIndex }) => {
  if (!headings) {
    return null;
  }

  return (
    <ul>
      {headings.map((heading) => (
        <HeadingLink
          heading={heading}
          activeIndex={activeIndex}
          key={heading.url}
        >
          {heading.items && heading.items.length > 0 && (
            <ul>
              {heading.items.map((child) => (
                <HeadingLink
                  heading={child}
                  activeIndex={activeIndex}
                  key={child.url}
                />
              ))}
            </ul>
          )}
        </HeadingLink>
      ))}
    </ul>
  );
};

const TableOfContents = ({ tableOfContents }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const { getIndexFromId, nestedHeadings } = useHeadingsData();
  useIntersectionObserver(getIndexFromId, setActiveIndex);

  if (!tableOfContents) {
    return null;
  }

  return (
    <>
      <nav className="TableOfContents" aria-label="Table of contents">
        <HeadingsList
          activeIndex={activeIndex}
          headings={
            nestedHeadings.length > 0
              ? nestedHeadings
              : tableOfContents.items || []
          }
        />
      </nav>
    </>
  );
};

export default TableOfContents;
