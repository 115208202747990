import React from "react";
import Button from "./Button";
import { LocalizedLink } from "gatsby-theme-i18n";

// ❧
// Let your readers benefit from centuries of
// typographic experience and
// 20 years of development work.
// ❧
// Make your online text editions look as natural
// grey: kick off?
// as a beautiful manuscript and as brillant
// as the best prints of the last 500 years.
// ❧

const CallToAction = (props) => {
  return (
    <div className="CallToAction">
      {props.children ? (
        <div className="CallToAction-tagline">{props.children}</div>
      ) : null}
      <div className="CallToAction-ornament">❧</div>
      {props.buttonLabel ? (
        <Button is={LocalizedLink} to="/contact/">
          {props.buttonLabel}
        </Button>
      ) : null}
    </div>
  );
};

CallToAction.defaultProps = {
  buttonLabel: "License Andron",
};

export default CallToAction;
