import "draft-js/dist/Draft.css";
import "typor/dist/TypeTester.css";

import * as React from "react";
import { MDXProvider } from "@mdx-js/react";
// import { useTranslation } from "react-i18next";
import {
  MdxLink,
  // LocalizedLink
} from "gatsby-theme-i18n";

import Figure from "./Figure";
import Dropcap from "./Dropcap";
import Gallery from "./Gallery";
import Wrapper from "./Wrapper";
import Article from "./Article";
import AndronTester from "./AndronTester";
import Button from "./Button";
import CallToAction from "./CallToAction";

const components = {
  a: MdxLink,
  // img: Figure,
  Figure,
  Dropcap,
  Gallery,
  Wrapper,
  Article,
  TypeTester: AndronTester,
  Button: Button,
  CallToAction,
};

const Layout = ({ children, ...remainingProps }) => {
  // const { t } = useTranslation();

  return (
    <React.Fragment>
      <main>
        <MDXProvider components={components}>{children}</MDXProvider>
      </main>
    </React.Fragment>
  );
};

export default Layout;
